import React, { useEffect, useContext } from 'react'

import { checkUserAuth } from '../../store/actions/userActions'
import { UserContext } from '../../store/contexts/UserContext'

const Order = props => {

  const { user } = useContext(UserContext)

  useEffect(() => {
    checkUserAuth(props.history)
  })

  useEffect(() => {
    if (user.permissions && !user.permissions.includes('order') && !user.permissions.includes('admin')) {
      props.history.replace('/')
    }
  })

  return (
    <div className='Order'>
      <h1>Orders Coming Soon</h1>
    </div>
  )
}

export default Order