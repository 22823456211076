import React, { useEffect, useState } from 'react'
import { startCase, isEqual } from 'lodash'

import { getUser, changeUserInfo } from '../../../store/actions/userActions'

import Loading from '../../helper/Loading'
import MessageModal from '../../helper/MessageModal'
import GoBack from '../../helper/GoBack'

const User = props => {

  const [user, setUser] = useState({})
  const [fields, setFields] = useState([])
  const [originalData, setOriginalData] = useState({
    permissions: [],
    startDate: '',
    qualifiedJobs: [],
    startingRate: 0,
    currentRate: 0,
    adminNotes: ''
  })
  const [formData, setFormData] = useState({
    permissions: [],
    startDate: '',
    qualifiedJobs: [],
    startingRate: 0,
    currentRate: 0,
    adminNotes: ''
  })
  const [dataChange, setDataChange] = useState(false)
  const [permissions, setPermissions] = useState({
    admin: false,
    list: false,
    check: false,
    order: false,
    manage: false
  })
  const [qualifiedJobs, setQualifiedJobs] = useState({
    sourcingAnalist: false,
    checkingAnalist: false,
    amazonSpecialist: false,
    customerService: false,
    orderingSpecialist: false,
    manager: false
  })

  useEffect(() => {
    getUser(props.match.params.slug)
      .then(res => {
        setUser(res)
      })
      .catch(err => {
        console.log(err)
      })
  }, [props.match.params.slug])

  useEffect(() => {
    const tempFields = []
    for (const key in user) {
      // if (typeof user[key] === 'string') {
      if (key !== 'active' && key !== 'permissions') {
        tempFields.push(
          <div key={key}>
            <label>{startCase(key)}</label>
            <input
              type='text'
              name={key}
              placeholder={key}
              value={user[key]}
              disabled
              // onChange={handleFormChange}
            />
          </div>
        )
      }
    }
    setFields(tempFields)
    const originalObj = {
      active: user.active,
      permissions: user.permissions,
      qualifiedJobs: user.qualifiedJobs || [],
      adminNotes: user.adminNotes || '',
      startDate: user.startDate || '',
      startingRate: user.startingRate || 0,
      currentRate: user.currentRate || 0
    }
    setFormData(prevState => {
      return {
        ...prevState,
        ...originalObj
      }
    })
    setOriginalData(prevState => {
      return {
        ...prevState,
        ...originalObj
      }
    })
    if (user.permissions) {
      setPermissions(prevState => {
        const newObj = {}
        for (const key in prevState) {
          newObj[key] = user.permissions.includes(key) ? true : false
        }
        return newObj
      })
    }
    if (user.qualifiedJobs) {
      setQualifiedJobs(prevState => {
        const newObj = {}
        for (const key in prevState) {
          newObj[key] = user.qualifiedJobs.includes(key) ? true : false
        }
        return newObj
      })
    }
  }, [user])

  useEffect(() => {
    setDataChange(!isEqual(formData, originalData))
  }, [formData, originalData])

  const handlePermissionsChange = e => {
    setPermissions({
      ...permissions,
      [e.target.name]: !permissions[e.target.name]
    })

    const tempObj = {
      ...permissions,
      [e.target.name]: !permissions[e.target.name]
    }
    const newArr = Object.keys(tempObj).filter(item => tempObj[item])

    setFormData({
      ...formData,
      permissions: newArr
    })
  }

  const handleQualifiedJobsChange = e => {
    setQualifiedJobs({
      ...qualifiedJobs,
      [e.target.name]: !qualifiedJobs[e.target.name]
    })

    const tempObj = {
      ...qualifiedJobs,
      [e.target.name]: !qualifiedJobs[e.target.name]
    }
    const newArr = Object.keys(tempObj).filter(item => tempObj[item])

    setFormData({
      ...formData,
      qualifiedJobs: newArr
    })
  }

  const handleDropdownChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === 'yes' ? true : false
    })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: '',
    timeout: null
  })

  const handleFormChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    changeUserInfo(props.match.params.slug, formData)
      .then(res => {
        setIsLoading(false)
        setModal({
          isOpen: true,
          header: 'Info Changed Successfully',
          message: '',
          timeout: 3000
        })
      })
      .catch(err => {
        setIsLoading(false)
        setModal({
          isOpen: true,
          header: 'An Error Occurred While Updating Info',
          message: err,
          timeout: null
        })
      })
  }

  return (
    <div className='User'>
      <Loading isOpen={isLoading} />
      <MessageModal
        isOpen={modal.isOpen}
        header={modal.header}
        message={modal.message}
        timeout={modal.timeout}
        onModalClose={() => setModal({...modal, isOpen: false})}
      />
      <GoBack history={props.history} />
      <h1>{user.firstName} {user.lastName}</h1>
      <form>
        <label>Active</label>
        <select
          name='active'
          value={formData.active === true ? 'yes' : 'no'}
          onChange={handleDropdownChange}
        >
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </select>
        <label>Permissions</label>
        <label>
          <input
            type='checkbox'
            name='admin'
            checked={permissions.admin}
            onChange={handlePermissionsChange}
          />
          <span>Admin</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='list'
            checked={permissions.list}
            onChange={handlePermissionsChange}
          />
          <span>Lister</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='check'
            checked={permissions.check}
            onChange={handlePermissionsChange}
          />
          <span>Checker</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='order'
            checked={permissions.order}
            onChange={handlePermissionsChange}
          />
          <span>Orders</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='manage'
            checked={permissions.manage}
            onChange={handlePermissionsChange}
          />
          <span>Manage</span>
        </label>
        <label>Start Date</label>
        <input
          type='date'
          name='startDate'
          placeholder='Start Date'
          value={formData.startDate}
          onChange={handleFormChange}
        />
        <label>Jobs Qualified For</label>
        <label>
          <input
            type='checkbox'
            name='sourcingAnalist'
            checked={qualifiedJobs.sourcingAnalist}
            onChange={handleQualifiedJobsChange}
          />
          <span>Sourcing Analist</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='checkingAnalist'
            checked={qualifiedJobs.checkingAnalist}
            onChange={handleQualifiedJobsChange}
          />
          <span>Checking Analist</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='amazonSpecialist'
            checked={qualifiedJobs.amazonSpecialist}
            onChange={handleQualifiedJobsChange}
          />
          <span>Amazon Specialist</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='customerService'
            checked={qualifiedJobs.customerService}
            onChange={handleQualifiedJobsChange}
          />
          <span>Customer Service Rep</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='orderingSpecialist'
            checked={qualifiedJobs.orderingSpecialist}
            onChange={handleQualifiedJobsChange}
          />
          <span>Ordering Specialist</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='manager'
            checked={qualifiedJobs.manager}
            onChange={handleQualifiedJobsChange}
          />
          <span>Manager</span>
        </label>
        <label>Starting Rate ($ USD)</label>
        <input
          required
          type='number'
          step="0.01"
          name='startingRate'
          placeholder='Starting Rate'
          value={formData.startingRate}
          onChange={handleFormChange}
        />
        <label>Current Rate ($ USD)</label>
        <input
          required
          type='number'
          step="0.01"
          name='currentRate'
          placeholder='Current Rate'
          value={formData.currentRate}
          onChange={handleFormChange}
        />
        <label>Admin Notes</label>
        <input
          type='text'
          name='adminNotes'
          placeholder='Admin Notes'
          value={formData.adminNotes}
          onChange={handleFormChange}
        />
        <button disabled={!dataChange} onClick={handleSubmit}>Save Changes</button>
        <br />
        {/* PHASE TWO BUILD */}
        <h3>Job Application Fields</h3>
        {fields}
      </form>
    </div>
  )
}

export default User