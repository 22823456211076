import moment from 'moment'

import firebase from '../../config/firebase'
import 'firebase/auth'
import 'firebase/firestore'

import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER_EMAIL,
  UPDATE_USER_INFO
} from '../types'

const errorFunction = (errorMessage) => {
  console.log('firebase error message: ', errorMessage)
}

export const createNewUser = (formData, errorFunc=errorFunction) => {
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(formData.email, formData.password)
      .then(res => {
        const newInfo = {...formData}
        newInfo.active = false
        newInfo.permissions = []
        newInfo.createdAt = moment().unix()
        delete newInfo.password
        delete newInfo.confirmPassword
        firebase.firestore().collection('users').doc(res.user.uid).set(newInfo)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            errorFunc(error.message)
            reject(error.message)
          })
      })
      .catch(error => {
        errorFunc(error.message)
        reject(error.message)
      })
  })
}

export const loginUser = (credentials, dispatch, errorFunc=errorFunction) => {
  return new Promise((resolve, reject) => {
    firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(res => {
        const user = {
          uid: res.user.uid,
          email: res.user.email
        }
        firebase.firestore().collection('users').doc(res.user.uid).get()
          .then(response => {
            const userData = response.data()
            for (const key in userData) {
              user[key] = userData[key]
            }
            if (user.active) {
              dispatch({
                type: LOGIN_USER,
                user
              })
              resolve(user)
            } else {
              errorFunc('This user is not active')
              reject('This user is not active')
            }
          })
          .catch(err => {
            errorFunc(err.message)
            reject(err.message)
          })
      })
      .catch(error => {
        errorFunc(error.message)
        reject(error.message)
      })
  })
}

export const checkUserAuth = history => {
  return firebase.auth().onAuthStateChanged(user => {
    if (!user) {
      history.push('/login')
    }
  })
}

export const logoutUser = dispatch => {
  firebase.auth().signOut()
  localStorage.clear('user')
  dispatch({
    type: LOGOUT_USER
  })
}

export const changeUserEmail = (newEmail, dispatch, errorFunc=errorFunction) => {
  return new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    user.updateEmail(newEmail)
      .then(res => {
        dispatch({
          type: UPDATE_USER_EMAIL,
          email: newEmail
        })
        resolve(res)
      }).catch(err => {
        errorFunc(err.message)
        reject(err.message)
      });
  })
}

export const changeUserInfo = (uid, infoObj, dispatch, errorFunc=errorFunction) => {
  return new Promise((resolve, reject) => {
    firebase.firestore().collection('users').doc(uid).update({...infoObj})
      .then(res => {
        if (dispatch) {
          dispatch({
            type: UPDATE_USER_INFO,
            userInfo: infoObj
          })
        }
        resolve(res)
      })
      .catch(err => {
        errorFunc(err.message)
        reject(err.message)
      })
  })
}

export const changeUserPassword = (password, errorFunc=errorFunction) => {
  return new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    user.updatePassword(password)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        errorFunc(err.message)
        reject(err.message)
      })
  })
}

export const sendPasswordResetEmail = (email, errorFunc=errorFunction) => {
  return new Promise((resolve, reject) => {
    firebase.auth().sendPasswordResetEmail(email)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        errorFunc(err.message)
        reject(err.message)
      })
  })
}

export const getAllUsers = (errorFunc=errorFunction) => {
  return new Promise((resolve, reject) => {
    firebase.firestore().collection('users').get()
      .then(snapshot => {
        const users = []
        snapshot.forEach(doc => {
          const user = {...doc.data(), uid: doc.id}
          users.push(user)
        });
        resolve(users)
      })
      .catch(err => {
        errorFunc(err.message)
        reject(err.message)
      })
  })
}

export const getUser = (id, errorFunc=errorFunction) => {
  return new Promise((resolve, reject) => {
    firebase.firestore().collection('users').doc(id).get()
      .then(res => {
        resolve(res.data())
      })
      .catch(err => {
        errorFunc(err.message)
        reject(err.message)
      })
  })
}