import React, { useContext } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserContext } from '../store/contexts/UserContext'
import { logoutUser } from '../store/actions/userActions'

const Navbar = () => {

  const { user, dispatch } = useContext(UserContext)

  const handleLogout = () => {
    logoutUser(dispatch)
  }

  if (user.uid) {
    return (
      <nav className='Navbar'>
        <div className="nav-left">
          <NavLink exact to='/' activeClassName='nav-link-active'><FontAwesomeIcon icon='home' /></NavLink>
          {user.permissions.includes('admin') || user.permissions.includes('list') ?
            <NavLink to='/list' activeClassName='nav-link-active'><FontAwesomeIcon icon='clipboard-list' /></NavLink>
          : null}
          {user.permissions.includes('admin') || user.permissions.includes('check') ?
            <NavLink to='/check' activeClassName='nav-link-active'><FontAwesomeIcon icon='clipboard-check' /></NavLink>
          : null}
          {user.permissions.includes('admin') || user.permissions.includes('order') ?
            <NavLink to='/order' activeClassName='nav-link-active'><FontAwesomeIcon icon='shopping-bag' /></NavLink>
          : null}
        </div>
        <div className="nav-right">
          {user.permissions.includes('admin') ? 
            <NavLink to='/admin' activeClassName='nav-link-active'><FontAwesomeIcon icon='user-tie'/></NavLink>
          : null}
          <NavLink to='/profile' activeClassName='nav-link-active'><FontAwesomeIcon icon='user'/></NavLink>
          <Link to='/login' onClick={handleLogout}><FontAwesomeIcon icon='sign-out-alt'/></Link>
        </div>
      </nav>
    )
  } else {
    return <nav className="Navbar">
      <div className="nav-left"></div>
      <div className="nav-right">
        <NavLink to='login' activeClassName='nav-link-active'><FontAwesomeIcon icon='sign-in-alt' /></NavLink>
      </div>
    </nav>
  }
}

export default Navbar