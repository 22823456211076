import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { checkUserAuth, getAllUsers } from '../../../store/actions/userActions'
import { UserContext } from '../../../store/contexts/UserContext'

import FastChecking from './FastChecking'

const Check = props => {

  const { user } = useContext(UserContext)
  
  useEffect(() => {
    checkUserAuth(props.history)
  })

  useEffect(() => {
    if (user.permissions && !user.permissions.includes('check') && !user.permissions.includes('admin')) {
      props.history.replace('/')
    }
  })

  const [open, setOpen] = useState(false)
  const [userDivs, setUserDivs] = useState([])

  useEffect(() => {
    getAllUsers()
      .then(userList => {
        const divs = []
        userList.forEach(user => {
          divs.push((
            <div key={user.uid}>
              <Link to={`check/${user.uid}`}>
                <p>{user.firstName} {user.lastName}</p>
              </Link>
            </div>
          ))
        })
        setUserDivs([...divs])
      })
      .catch(err => {
        setUserDivs([(
          <div className="red-text">
            <h1>There was an error getting the users:</h1>
            <p>{err}</p>
          </div>
        )])
      })
  }, [])

  return (
    <div className='Check'>
      <h1>Checking</h1>
      <h2 className='clickable' onClick={() => setOpen(true)}><FontAwesomeIcon icon='bolt' /> Fast Checking</h2>
      <FastChecking open={open} onClose={() => setOpen(false)} />
      <p>Check By Person:</p>
      <div>
        {userDivs}
      </div>
    </div>
  )
}

export default Check