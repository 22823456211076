import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { sendPasswordResetEmail } from '../../../store/actions/userActions'

import MessageModal from '../../helper/MessageModal'
import Loading from '../../helper/Loading'

const ForgotPassword = props => {

  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: ''
  })

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    sendPasswordResetEmail(email)
      .then((res) => {
        setIsLoading(false)
        setModal({
          isOpen: true,
          header: 'Password Reset Link sent',
          message: res
        })
        setTimeout(() => {
          props.history.push('/login')
        }, 3000)
      })
      .catch((err) => {
        setIsLoading(false)
        setModal({
          isOpen: true,
          header: 'Something Went Wrong',
          message: err
        })
      })
  }

  return (
    <div className='ForgotPassword'>
      <Loading isOpen={isLoading} />
      <MessageModal
        isOpen={modal.isOpen}
        header={modal.header}
        message={modal.message}
        onModalClose={() => setModal({...modal, isOpen: false})}
      />
      <h1>Forgot Password</h1>
      <p>Enter Email address to get a password reset link</p>
      <form>
        <label><FontAwesomeIcon icon='envelope' /></label>
        <input
          type='email'
          name='email'
          placeholder='Email'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <div className="forgot-password-buttons">
          <button onClick={handleSubmit}>Reset Password</button>
          <Link to='/login'>Login</Link>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword