import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { getAllUsers } from '../../../store/actions/userActions'

import GoBack from '../../helper/GoBack'

const Users = props => {

  const [userDivs, setUserDivs] = useState([])
  const [users, setUsers] = useState([])
  const [currentUsers, setCurrentUsers] = useState([])

  useEffect(() => {
    getAllUsers()
      .then(userList => {
        setUsers([...userList])
        setCurrentUsers([...userList])
      })
      .catch(err => {
        setUserDivs([(
          <div className="red-text">
            <h1>There was an error getting the users:</h1>
            <p>{err}</p>
          </div>
        )])
      })
  }, [])

  useEffect(() => {
    const divs = []
    currentUsers.forEach(user => {
      divs.push((
        <div className='filtered-users-user' key={user.uid}>
          <Link 
            to={`users/${user.uid}`}
          >
            <FontAwesomeIcon icon='user-cog' />
            </Link>
            <p>{user.firstName} {user.lastName}</p>
        </div>
      ))
    })
    setUserDivs([...divs])
  }, [currentUsers])

  const handleFilterChange = e => {
    switch (e.target.value) {
      case 'all':
        setCurrentUsers([...users])
        break
      case 'active':
        setCurrentUsers(users.filter(user => user.active))
        break
      case 'admins':
        setCurrentUsers(users.filter(user => user.permissions.includes('admin')))
        break
      case 'nonAdmins':
        setCurrentUsers(users.filter(user => !user.permissions.includes('admin')))
        break
      case 'listers':
        setCurrentUsers(users.filter(user => user.permissions.includes('list')))
        break
      case 'checkers':
        setCurrentUsers(users.filter(user => user.permissions.includes('check')))
        break
      default:
        break
    }
  }

  return (
    <div className='Users'>
      <GoBack history={props.history} />
      <h1>Users:</h1>
      <form>
        <label>Filter Users</label>
        <select
          required
          name='filter'
          onChange={handleFilterChange}
        >
          <option value='all'>All</option>
          <option value='active'>Active</option>
          <option value='admins'>Admins</option>
          <option value='nonAdmins'>Non Admins</option>
          <option value='listers'>Listers</option>
          <option value='checkers'>Checkers</option>
        </select>
      </form>
      <div className='filtered-users'>
        {userDivs}
      </div>
    </div>
  )
}

export default Users