import React, { useState, useEffect, useContext, useCallback } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import firebase from '../../../config/firebase'
import 'firebase/firestore'
import { UserContext } from '../../../store/contexts/UserContext'

import MessageModal from '../../helper/MessageModal'
import Loading from '../../helper/Loading'

const ListingForm = ({merchant, supplier}) => {

  const { user } = useContext(UserContext)

  const [merchantProductIdCheck, setMerchantProductIdCheck] = useState(<p>The {merchant.productIdName} must be unique</p>)
  const [freshMerchantProductId, setFreshMerchantProductId] = useState(false)

  const initialFormData = {
    supplierUrl: '',
    supplierProductId: '',
    supplierPrice: '',
    multipack: false,
    qty: 1,
    weight: '',
    merchantUrl: '',
    merchantProductId: '',
    productName: '',
    ourPrice: '',
    buyBox: false,
    position: '',
    bsr: '',
    listerNotes: '',
    minProfit: false,
    estimatedProfit: false,
    estimatedProfitPercent: false
  }
  const [formData, setFormData] = useState(initialFormData)

  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: '',
    onModalClose: () => setModal({...modal, isOpen: false})
  })

  const [isLoading, setIsLoading] = useState(false)

  const openModal = (header, message='') => {
    setModal({
      ...modal,
      isOpen: true,
      header,
      message
    })
  }

  const handleFormChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.name === 'position' || e.target.name === 'bsr' ? parseInt(e.target.value) : e.target.value
    })
  }

  const validateMerchantProductId = () => {
    const id = formData.merchantProductId
    if (id) {
      setMerchantProductIdCheck(<p><FontAwesomeIcon icon='spinner' spin={true} className='pink-text' /></p>)
      firebase.firestore().collection('listings')
      .where('merchantProductId', '==', id)
      .where('merchant', '==', merchant.camelName)
      .get()
        .then(snapshot => {
          if (snapshot.empty) {
            setFreshMerchantProductId(true)
            setMerchantProductIdCheck(
              <p className='green-text'>
                This {merchant.productIdName} is Available <FontAwesomeIcon icon='thumbs-up' />
              </p>)
          } else {
            setFreshMerchantProductId(false)
            setMerchantProductIdCheck(
              <p className='red-text'>
                This {merchant.productIdName} has already been used <FontAwesomeIcon icon='thumbs-down' />
              </p>)
          }
        })
        .catch(err => {
          openModal('An Error Occurred Trying to Check this ' + merchant.productIdName, err.message)
        })
    }
  }

  const checkUrlForAsin = e => {
    if (supplier.camelName === 'amazon' && e.target.name === 'supplierUrl' && !formData.supplierProductId) {
      e.target.value.split('/').forEach(str => {
        if (str.length === 10 && /([A-Z0-9]{10})/.test(str)) {
          setFormData(fd => ({...fd, supplierProductId: str}))
        }
      })
    } else if (merchant.camelName === 'amazon' && e.target.name === 'merchantUrl' && !formData.merchantProductId) {
      e.target.value.split('/').forEach(str => {
        if (str.length === 10 && /([A-Z0-9]{10})/.test(str)) {
          setFormData(fd => ({...fd, merchantProductId: str}))
        }
      })
    }
  }

  const formatNumber = e => {
    if (e.target.value) {
      setFormData({
        ...formData,
        [e.target.name]: parseFloat(e.target.value).toFixed(2)
      })
    }
  }

  const handleRadioChange = e => {
    setFormData({
      ...formData,
      qty: e.target.name === 'multipack' && e.target.value === 'no' ? 1 : formData.qty,
      [e.target.name]: e.target.value === 'yes' ? true : false
    })
  }

  const walmartFormula = useCallback(() => {
    const estimateFormula = '15% min $3.50'
    const qty = formData.qty
    const supplierPrice = formData.supplierPrice * qty
    const minProfit = Math.max(supplierPrice * 0.15, 3.5)
    const minPrice = (minProfit + supplierPrice) / 0.85
    const ourPrice = formData.ourPrice
    const estimatedProfit = (ourPrice * 0.85) - supplierPrice
    const estimatedProfitPercent = estimatedProfit / supplierPrice
    const profitable = Math.round(estimatedProfit * 100) / 100 >= minProfit ? true : false
    setFormData(ps => ({
      ...ps,
      estimateFormula,
      minPrice: supplierPrice && qty ? minPrice : false,
      minProfit: supplierPrice && qty ? minProfit : false,
      estimatedProfit: supplierPrice && qty && ourPrice ? estimatedProfit : false,
      estimatedProfitPercent: supplierPrice && qty && ourPrice ? estimatedProfitPercent : false,
      profitable: supplierPrice && qty && ourPrice ? profitable : false
    }))
  }, [formData.supplierPrice, formData.qty, formData.ourPrice])

  const amazonFormula = useCallback(() => {
    const estimateFormula = 'Min 5% + 9% tax. Shipping 5.99 if > $35'
    const qty = formData.qty
    const ourPrice = formData.ourPrice
    const supplierPrice = formData.supplierPrice
    const buyPrice = supplierPrice * qty
    const shipping = buyPrice < 35 ? 5.99 : 0
    const priceTaxShip = (buyPrice * 1.09) + shipping
    const priceWithMerchantFee = ourPrice * 0.85
    const estimatedProfit = priceWithMerchantFee - priceTaxShip
    const estimatedProfitPercent = estimatedProfit / priceTaxShip
    const profitable = estimatedProfitPercent >= 0.0498 ? true : false
    const minProfit = priceTaxShip * 0.05
    const minPrice = (minProfit + priceTaxShip) / 0.85
    setFormData(ps => ({
      ...ps,
      estimateFormula,
      minPrice: supplierPrice && qty ? minPrice : false,
      minProfit: supplierPrice && qty ? minProfit : false,
      estimatedProfit: supplierPrice && qty && ourPrice ? estimatedProfit : false,
      estimatedProfitPercent: supplierPrice && qty && ourPrice ? estimatedProfitPercent : false,
      profitable: supplierPrice && qty && ourPrice ? profitable : false
    }))
  }, [formData.qty, formData.ourPrice, formData.supplierPrice])

  useEffect(() => {
    switch (merchant.camelName) {
      case 'walmart':
        walmartFormula()
        break
      case 'amazon':
        amazonFormula()
        break
      default:
        break
    }
  }, [merchant.camelName, walmartFormula, amazonFormula])

  const handleSubmit = e => {
    e.preventDefault()

    // eslint-disable-next-line
    const UrlRegEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    if (!UrlRegEx.test(String(formData.supplierUrl))) {
      openModal('Please Enter a Valid Supplier URL')
    } else if (!formData.supplierProductId) {
      openModal(`Please Enter a Valid ${supplier.productIdName}`)
    } else if (formData.supplierPrice < 0.01) {
      openModal('Please Enter a Valid Supplier Price')
    } else if (formData.qty < 1) {
      openModal('Please Enter a Valid Quantity', 'Remember, it should be 1 unless you are creating a multipack')
    } else if (formData.weight < 0.01 && merchant.camelName !== 'amazon') {
      openModal('Please Enter a Valid Shipping Weight')
    } else if (!UrlRegEx.test(String(formData.merchantUrl)) && merchant.camelName !== 'amazon') {
      openModal(`Please Enter a Valid ${merchant.title} URL`)
    } else if (!freshMerchantProductId) {
      openModal('You cannot submit a product that has already been listed')
    } else if (!formData.productName) {
      openModal('Please Enter a Valid Product Name')
    } else if (formData.ourPrice < 0.01) {
      openModal('Please Enter a Valid Sell Price')
    } else if (formData.position < 1 && merchant.camelName === 'amazon') {
      openModal('Please Enter a Valid Position')
    } else if (formData.bsr < 1 && merchant.camelName === 'amazon') {
      openModal('Please Enter a Valid BSR')
    } else if (!formData.profitable) {
      openModal('Please Enter a Profitable Product')
    } else {
      setIsLoading(true)

      const newObj = {
        createdBy: user.uid,
        listerName: `${user.firstName} ${user.lastName}`,
        merchant: merchant.camelName,
        supplier: supplier.camelName,
        createdAt: moment().unix(),
        checked: false,
        locked: false
      }
      for (const key in formData) {
        newObj[key] = parseFloat(formData[key]) || formData[key]
      }

      firebase.firestore().collection('listings').doc().set(newObj)
        .then(() => {
          setIsLoading(false)
          openModal('Listing Created Successfully!')
          setFormData(initialFormData)
          setMerchantProductIdCheck(<p>The {merchant.productIdName} must be unique</p>)
          setFreshMerchantProductId(false)
        })
        .catch(err => {
          setIsLoading(false)
          openModal('Something Went Wrong Creating Your Listing', err.message)
        })
    }
  }

  return (
    <form className='ListingForm'>
      {merchant.camelName === 'walmart' ?
        <div>
          <p>Check as you go to make sure your listings meet the following criteria:</p>
          <p>-Must be Profitable (verify in this form)</p>
          <p>-Must be Prime shipping - must have Prime symbol</p>
          <p>-Must be a Match</p>
          <p>-Do Not list items where seller = brand</p>
          <p>-Make sure quantities match (ex. 3 bags of beans on Amazon and 3 bags of beans on Walmart is a quantity match. 1 bag of beans on Amazon and 3 bags of beans on Walmart is not a quantity of match…do not list)</p>
          <p>-Must be in New Condition</p>
        </div>
      : null}
      <label>{supplier.title} URL</label>
      <input
        required
        type='url'
        name='supplierUrl'
        placeholder={supplier.title + ' URL'}
        value={formData.supplierUrl}
        onChange={handleFormChange}
        onBlur={checkUrlForAsin}
      />
      <label>{supplier.productIdName}</label>
      <input
        required
        type='text'
        name='supplierProductId'
        placeholder={supplier.productIdName}
        value={formData.supplierProductId}
        onChange={handleFormChange}
      />
      <label>{supplier.title} Price (in USD $)</label>
      <input
        required
        type='number'
        name='supplierPrice'
        placeholder='e.g. 5.00'
        min='0.01'
        step='0.01'
        value={formData.supplierPrice}
        onChange={handleFormChange}
        onBlur={formatNumber}
      />
      <label>Is this a Multipack?</label>
      <label>
        <input
          type='radio'
          name='multipack'
          value='yes'
          checked={formData.multipack}
          onChange={handleRadioChange}
        />
        <span>Yes</span>
      </label>
      <label>
        <input
          type='radio'
          name='multipack'
          value='no'
          checked={!formData.multipack}
          onChange={handleRadioChange}
        />
        <span>No</span>
      </label>
      {formData.multipack ?
        <>
        <label>Qty (>1 only if you create multipack)</label>
        <input
          required
          type='number'
          name='qty'
          placeholder='qty'
          min='1'
          step='1'
          value={formData.qty}
          onChange={handleFormChange}
        />
        </>
      : null}
      <label>Shipping Weight (pounds)</label>
      <input
        required
        type='number'
        name='weight'
        placeholder='Shipping Weight'
        min='0'
        step='0.01'
        value={formData.weight}
        onChange={handleFormChange}
        onBlur={formatNumber}
      />
      <label>{merchant.title} URL</label>
      <input
        required
        type='url'
        name='merchantUrl'
        placeholder={`${merchant.title} URL`}
        value={formData.merchantUrl}
        onChange={handleFormChange}
        onBlur={checkUrlForAsin}
      />
      <label>{merchant.productIdName}</label>
      <input
        required
        type='text'
        name='merchantProductId'
        placeholder={merchant.productIdName}
        value={formData.merchantProductId}
        onChange={handleFormChange}
        onBlur={validateMerchantProductId}
      />
      {merchantProductIdCheck}
      <label>Product Name</label>
      <input
        required
        type='text'
        name='productName'
        placeholder='Product Name'
        value={formData.productName}
        onChange={handleFormChange}
      />
      {formData.minPrice && formData.minProfit ?
        <p className='green-text'>
          Minimum Sell Price: ${formData.minPrice.toFixed(2)} Minimum Profit: ${formData.minProfit.toFixed(2)}
        </p>
      : null}
      <label>Our {merchant.title} Sell Price (in USD $)</label>
      <input
        required
        type='number'
        name='ourPrice'
        placeholder='Our Sell Price'
        min='0.01'
        step='0.01'
        value={formData.ourPrice}
        onChange={handleFormChange}
        onBlur={formatNumber}
      />
      {formData.estimatedProfit ?
        <p className={formData.profitable ? 'green-text' : 'red-text'}>
          Estimated Profit: ${formData.estimatedProfit.toFixed(2)} or {(formData.estimatedProfitPercent * 100).toFixed(2)}% {formData.profitable ? <FontAwesomeIcon icon='thumbs-up' /> : <FontAwesomeIcon icon='thumbs-down' />}
        </p>
      : null}
      {merchant.camelName === 'amazon' ?
        <>
          <label>Buy Box?</label>
          <label>
            <input
              type='radio'
              name='buyBox'
              value='yes'
              checked={formData.buyBox}
              onChange={handleRadioChange}
            />
            <span>Yes</span>
          </label>
          <label>
            <input
              type='radio'
              name='buyBox'
              value='no'
              checked={!formData.buyBox}
              onChange={handleRadioChange}
            />
            <span>No</span>
          </label>
          <label>Amazon Position</label>
          <input
            required
            type='number'
            name='position'
            placeholder='Position'
            min='1'
            step='1'
            value={formData.position}
            onChange={handleFormChange}
          />
          <label>Listed BSR #</label>
          <input
            required
            type='number'
            name='bsr'
            placeholder='BSR'
            min='1'
            step='1'
            value={formData.bsr}
            onChange={handleFormChange}
          />
        </>
      : null}
      <label>Notes</label>
      <input
        type='text'
        name='listerNotes'
        placeholder='Notes'
        value={formData.listerNotes}
        onChange={handleFormChange}
      />
      <button onClick={handleSubmit}>Submit</button>
      <MessageModal {...modal} />
      <Loading isOpen={isLoading} />
    </form>
  )
}

export default ListingForm