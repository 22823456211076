import React, { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'

import firebase from '../../../config/firebase'
import 'firebase/firestore'

import GoBack from '../../helper/GoBack'
import AddSupplierMerchant from './AddSupplierMerchant'
import Loading from '../../helper/Loading'

const SuppliersMerchants = props => {
  
  const [isLoading, setIsLoading] = useState(false)
  const [trigger, setTrigger] = useState(false)

  const [path, setPath] = useState({})
  useEffect(() => {
    if (props.match.path === '/admin/suppliers') {
      setPath({
        name: 'supplier',
        title: 'Supplier'
      })
    } else if (props.match.path === '/admin/merchants') {
      setPath({
        name: 'merchant',
        title: 'Merchant'
      })
    }
  }, [props.match.path])

  const [supplierMerchants, setSupplierMerchants] = useState({})
  useEffect(() => {
    if (!isEmpty(path)) {
      firebase.firestore().collection(`${path.name}s`).get()
        .then(snapshot => {
          const tempObj = {}
          snapshot.forEach(doc => tempObj[doc.id] = {...doc.data(), reallyDelete: false})
          setSupplierMerchants(tempObj)
        })
        .catch(err => console.log(err.message))
    }
  }, [path, trigger])

  const deleteSupplierMerchant = useCallback(id => {
    setIsLoading(true)
    firebase.firestore().collection(`${path.name}s`).doc(id).delete()
      .then(() => {
        setTrigger(trigger => !trigger)
        setIsLoading(false)
      })
      .catch(() => {
        setTrigger(trigger => !trigger)
        setIsLoading(false)
      })
  }, [path.name])

  const [supplierMerchantList, setSupplierMerchantList] = useState([])

  useEffect(() => {
    const keys = Object.keys(supplierMerchants)
    if (keys.length > 0) {
      const tempArr = []
      keys.forEach(key => {
        const sm = supplierMerchants[key]
        tempArr.push(
        <p key={sm.camelName}>{sm.reallyDelete ?
          <span className='red-text-clickable' onClick={() => deleteSupplierMerchant(sm.camelName)}>Really?</span> 
          : <FontAwesomeIcon icon='trash' onClick={() => setSupplierMerchants({...supplierMerchants, [key]: {...supplierMerchants[key], reallyDelete: true}})} />} {sm.title} (Product ID Name: {sm.productIdName})
        </p>
        )
      })
      setSupplierMerchantList(tempArr)
    }
  }, [supplierMerchants, deleteSupplierMerchant])

  const [formOpen, setFormOpen] = useState(false)

  return (
    <div className='SuppliersMerchants'>
      <GoBack history={props.history} />
      <h1>{path.title}s</h1>
      <AddSupplierMerchant
        isOpen={formOpen}
        onModalClose={() => {
          setFormOpen(false)
          setTrigger(!trigger)
        }}
        path={path}
      />
      <p className='clickable' onClick={() => setFormOpen(true)}>
        <FontAwesomeIcon icon='plus-circle' /> Add New {path.title}
      </p>
      <div>{supplierMerchantList}</div>
      <Loading isOpen={isLoading} />
    </div>
  )
}

export default SuppliersMerchants