import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import UserContextProvider from '../store/contexts/UserContext'
import Icons from '../config/icons'

import Navbar from './Navbar'
import Admin from './routes/admin/Admin'
import Home from './routes/Home'
import JobApplication from './routes/auth/JobApplication'
import Login from './routes/auth/Login'
import ForgotPassword from './routes/auth/ForgotPassword'
import Profile from './routes/auth/Profile'
import List from './routes/listings/List'
import Check from './routes/checking/Check'
import UserChecking from './routes/checking/UserChecking'
import Order from './routes/Order'
import Users from './routes/admin/Users'
import User from './routes/admin/User'
import SuppliersMerchants from './routes/admin/SuppliersMerchants'
import Oops from './routes/Oops'

Icons()

const App = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <UserContextProvider>
          <Navbar />
          <Route path='/admin' component={Admin} />
          <Switch>
            <Route exact path ='/' component={Home} />
            <Route path='/job-application' component={JobApplication} />
            <Route path='/login' component={Login} />
            <Route path='/forgot-password' component={ForgotPassword} />
            <Route path='/profile' component={Profile} />
            <Route path='/list' component={List} />
            <Route exact path='/check' component={Check} />
            <Route path='/check/:slug' component={UserChecking} />
            <Route path='/order' component={Order} />
            <Route exact path='/admin/users' component={Users} />
            <Route path='/admin/users/:slug' component={User} />
            <Route path='/admin/merchants' component={SuppliersMerchants} />
            <Route path='/admin/suppliers' component={SuppliersMerchants} />
            <Route component={Oops} />
          </Switch>
        </UserContextProvider>
      </BrowserRouter>
    </div>
  )
}

export default App