import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { createNewUser } from '../../../store/actions/userActions'

import MessageModal from '../../helper/MessageModal'
import Loading from '../../helper/Loading'

const JobApplication = props => {

  const [radioOptions, setRadioOptions] = useState({
    isAdult: 'no',
    shiftHours: 'no',
    availableForMeetings: 'no'
  })
  const [vaExperience, setVaExperience] = useState({
    sourcing: false,
    checking: false,
    amazonOrders: false,
    amazonTracking: false,
    amazonMetrics: false,
    amazonCustomerService: false,
    otherOrders: false,
    generalCustomerService: false,
    connectOrange: false,
    arbitrageSuite: false,
    otherSoftware: false,
    vaManagement: false
  })
  const [jobPreference, setJobPreference] = useState({
    vaLister: false,
    vaChecker: false,
    amazonSpecialist: false,
    manager: false,
    other: false,
  })
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    skypeName: '',
    payPal: '',
    isAdult: false,
    education: '',
    workExperience1: '',
    workExperience2: '',
    workExperience3: '',
    vaExperience: [],
    experienceDetails: '',
    jobPreference: [],
    qualifiedResponse: '',
    hoursPerWeek: 0,
    shiftHours: false,
    bestHours: '',
    availableForMeetings: false,
    otherAbout: '',
    referrer: '',
    startAvailability: '',
    notes: ''
  })
  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: '',
    onModalClose: () => setModal({...modal, isOpen: false})
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleFormChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const passwordMatch = () => {
    if (formData.password && formData.confirmPassword) {
      if (formData.password === formData.confirmPassword) {
        return (
          <div className="green-text">Passwords match <FontAwesomeIcon icon='thumbs-up' /></div>
        )
      }
      else {
        return (
          <div className="red-text">Passwords do not match <FontAwesomeIcon icon='thumbs-down' /></div>
        )
      }
    }
  }

  const handleRadioChange = e => {
    setRadioOptions({
      ...radioOptions,
      [e.target.name]: e.target.value
    })
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === 'yes' ? true : false
    })
  }

  const handleVaExperienceChange = e => {
    setVaExperience({
      ...vaExperience,
      [e.target.name]: !vaExperience[e.target.name]
    })

    const tempObj = {
      ...vaExperience,
      [e.target.name]: !vaExperience[e.target.name]
    }
    const newArr = Object.keys(tempObj).filter(item => tempObj[item])

    setFormData({
      ...formData,
      vaExperience: newArr
    })
  }

  const handlejobPreferenceChange = e => {
    setJobPreference({
      ...jobPreference,
      [e.target.name]: !jobPreference[e.target.name]
    })

    const tempObj = {
      ...jobPreference,
      [e.target.name]: !jobPreference[e.target.name]
    }
    const newArr = Object.keys(tempObj).filter(item => tempObj[item])

    setFormData({
      ...formData,
      jobPreference: newArr
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    
    let allGood = true;

    const stopSubmit = () => {
      allGood = false
      setModal({
        ...modal,
        isOpen: true,
        header: 'Please Fill Out All Required Fields'
      })
    }

    if (formData.password !== formData.confirmPassword) {
      allGood = false
      setModal({
        ...modal,
        isOpen: true,
        header: 'Passwords must match'
      })
      return null
    }

    const requiredCheckboxes = ['jobPreference']
    requiredCheckboxes.forEach(field => {
      if (formData[field] < 1) {
        stopSubmit()
        return null
      }
    })

    const elements = document.getElementsByTagName('input')
    for (let element of elements) {
      if(element.hasAttribute('required')) {
        if (!formData[element.name]) {
          stopSubmit()
          return null
        } else if (element.type === 'email') {
          // eslint-disable-next-line
          const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (!emailRegEx.test(String(formData.email).toLowerCase())) {
            allGood = false
            setModal({
              ...modal,
              isOpen: true,
              header: 'Please enter a valid email'
            })
            return null
          }
        }
      }
    }

    if (allGood) {
      console.log('form submitted: ', formData)
      setIsLoading(true)
      createNewUser(formData)
        .then(res => {
          setIsLoading(false)
          setModal({
            isOpen: true,
            header: 'Job application submitted Successfully',
            message: 'A user has been created in the system for you using the email and password you provided. If you are hired, your login will be activated.',
            onModalClose: () => props.history.push('/login')
          })
        })
        .catch(err => {
          setIsLoading(false)
          setModal({
            ...modal,
            isOpen: true,
            header: 'Something Went Wrong',
            message: err
          })
        })
    }
  }

  return (
    <div className='JobApplication'>
      <h1>Prinicity Arbitrage Job Application</h1>
      <p>This Job Application will give us information to fill multiple job types that we offer.  Please fill out the whole application.</p>
      <p>Some questions include more details in this section as noted by matching black stars before the question.</p>
      <p>**Example of Details - 14 months as lister on Arbitrage Suite and 3 months as lister on Connect Orange</p>
      <p>***Shift work: Most positions have flexible hours.  There are some set shift hours around the clock available. We need to know if shift hours will work for you. If so, what times are you available and not available to work shifts.</p>
      <br />
      <form className="job-application-form" action='/thank-you'>
        <label>First Name</label>
        <input
          required
          type='text'
          name='firstName'
          placeholder='First Name'
          value={formData.firstName}
          onChange={handleFormChange}
        />
        <label>Last Name</label>
        <input
          required
          type='text'
          name='lastName'
          placeholder='Last Name'
          value={formData.lastName}
          onChange={handleFormChange}
        />
        <label>Email (will also be used for login)</label>
        <input
          required
          type='email'
          name='email'
          placeholder='Email'
          value={formData.email}
          onChange={handleFormChange}
        />
        <label>Create a New Password</label>
        <input
          required
          type='password'
          name='password'
          placeholder='Password'
          value={formData.password}
          onChange={handleFormChange}
        />
        <label>Confirm Password</label>
        <input
          required
          type='password'
          name='confirmPassword'
          placeholder='Password'
          value={formData.confirmPassword}
          onChange={handleFormChange}
        />
        {passwordMatch()}
        <label>Skype Name</label>
        <input
          required
          type='text'
          name='skypeName'
          placeholder='Skype Name'
          value={formData.skypeName}
          onChange={handleFormChange}
        />
        <label>PayPal Name</label>
        <input
          required
          type='text'
          name='payPal'
          placeholder='PayPal Name'
          value={formData.payPal}
          onChange={handleFormChange}
        />
        <label>Are you at least 18 years old?</label>
        <label>
          <input
            type='radio'
            name='isAdult'
            value='yes'
            checked={radioOptions.isAdult === 'yes'}
            onChange={handleRadioChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='isAdult'
            value='no'
            checked={radioOptions.isAdult === 'no'}
            onChange={handleRadioChange}
          />
          <span>No</span>
        </label>
        <label>Education: list degrees, majors, emphasis, training, certificates</label>
        <input
          required
          type='text'
          name='education'
          placeholder='Education'
          value={formData.education}
          onChange={handleFormChange}
        />
        <label>Most Relevant Work Experience #1: Name of Employer, Dates of employment, Position, Pay, Reason for leaving</label>
        <input
          required
          type='text'
          name='workExperience1'
          placeholder='Work Experience #1'
          value={formData.workExperience1}
          onChange={handleFormChange}
        />
        <label>Most Relevant Work Experience #2: Name of Employer, Dates of employment, Position, Pay, Reason for leaving</label>
        <input
          type='text'
          name='workExperience2'
          placeholder='Work Experience #2'
          value={formData.workExperience2}
          onChange={handleFormChange}
        />
        <label>Most Relevant Work Experience #3: Name of Employer, Dates of employment, Position, Pay, Reason for leaving</label>
        <input
          type='text'
          name='workExperience3'
          placeholder='Work Experience #3'
          value={formData.workExperience3}
          onChange={handleFormChange}
        />
        <label>Which VA responsibilities/skills do you have experience in? (Check all that apply)</label>
        <label>
          <input
            type="checkbox"
            name='sourcing'
            checked={vaExperience.sourcing}
            onChange={handleVaExperienceChange}
          />
          <span>Sourcing/Listing</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='checking'
            checked={vaExperience.checking}
            onChange={handleVaExperienceChange}
          />
          <span>Checking Listings</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='amazonOrders'
            checked={vaExperience.amazonOrders}
            onChange={handleVaExperienceChange}
          />
          <span>Amazon Specialist - Orders</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='amazonTracking'
            checked={vaExperience.amazonTracking}
            onChange={handleVaExperienceChange}
          />
          <span>Amazon Specialist - Tracking</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='amazonMetrics'
            checked={vaExperience.amazonMetrics}
            onChange={handleVaExperienceChange}
          />
          <span>Amazon Specialist - Amazon Central Metrics management</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='amazonCustomerService'
            checked={vaExperience.amazonCustomerService}
            onChange={handleVaExperienceChange}
          />
          <span>Amazon Specialist - Customer Service</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='otherOrders'
            checked={vaExperience.otherOrders}
            onChange={handleVaExperienceChange}
          />
          <span>Other Platform - Orders</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='generalCustomerService'
            checked={vaExperience.generalCustomerService}
            onChange={handleVaExperienceChange}
          />
          <span>General Customer Service</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='connectOrange'
            checked={vaExperience.connectOrange}
            onChange={handleVaExperienceChange}
          />
          <span>Connect Orange Software</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='arbitrageSuite'
            checked={vaExperience.arbitrageSuite}
            onChange={handleVaExperienceChange}
          />
          <span>Arbitrage Suite Software</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='otherSoftware'
            checked={vaExperience.otherSoftware}
            onChange={handleVaExperienceChange}
          />
          <span>Other arbitrage Software</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='vaManagement'
            checked={vaExperience.vaManagement}
            onChange={handleVaExperienceChange}
          />
          <span>Managing Teams of VAs</span>
        </label>
        <label>**Details about all experience checked above - length of time, depth of experience, which software, etc</label>
        <input
          required
          type='text'
          name='experienceDetails'
          placeholder='Details'
          value={formData.experienceDetails}
          onChange={handleFormChange}
        />
        <label>What type of job would you prefer? Check all that you are interested in</label>
        <label>
          <input
            type='checkbox'
            name='vaLister'
            checked={jobPreference.vaLister}
            onChange={handlejobPreferenceChange}
          />
          <span>VA Lister</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='vaChecker'
            checked={jobPreference.vaChecker}
            onChange={handlejobPreferenceChange}
          />
          <span>VA Checker</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='amazonSpecialist'
            checked={jobPreference.amazonSpecialist}
            onChange={handlejobPreferenceChange}
          />
          <span>Amazon Specialist</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='manager'
            checked={jobPreference.manager}
            onChange={handlejobPreferenceChange}
          />
          <span>Manager</span>
        </label>
        <label>
          <input
            type='checkbox'
            name='other'
            checked={jobPreference.other}
            onChange={handlejobPreferenceChange}
          />
          <span>Other - add in notes</span>
        </label>
        <label>What do you think makes you most qualified for this job(s)?</label>
        <input
          type='text'
          name='qualifiedResponse'
          placeholder='Answer...'
          value={formData.qualifiedResponse}
          onChange={handleFormChange}
        />
        <label>How many hours/week do you want to work?</label>
        <input
          required
          type='number'
          name='hoursPerWeek'
          placeholder='Number'
          value={formData.hoursPerWeek}
          onChange={handleFormChange}
        />
        <label>***Would you be willing to work shift hours?</label>
        <label>
          <input
            type='radio'
            name='shiftHours'
            value='yes'
            checked={radioOptions.shiftHours === 'yes'}
            onChange={handleRadioChange}
          />
          Yes
        </label>
        <label>
          <input
            type='radio'
            name='shiftHours'
            value='no'
            checked={radioOptions.shiftHours === 'no'}
            onChange={handleRadioChange}
          />
          No
        </label>
        <label>What hours best suit your schedule to work?</label>
        <input
          required
          type='text'
          name='bestHours'
          placeholder='Best Hours'
          value={formData.bestHours}
          onChange={handleFormChange}
        />
        <label>Could you make yourself available for regular and as needed team/one-on-one meetings?</label>
        <label>
          <input
            type='radio'
            name='availableForMeetings'
            value='yes'
            checked={radioOptions.availableForMeetings === 'yes'}
            onChange={handleRadioChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='availableForMeetings'
            value='no'
            checked={radioOptions.availableForMeetings === 'no'}
            onChange={handleRadioChange}
          />
          <span>No</span>
        </label>
        <label>What else would you like us to know about you?</label>
        <input
          type='text'
          name='otherAbout'
          placeholder='What else?'
          value={formData.otherAbout}
          onChange={handleFormChange}
        />
        <label>Who Referred You?</label>
        <input
          type='text'
          name='referrer'
          placeholder='Who Referred You?'
          value={formData.referrer}
          onChange={handleFormChange}
        />
        <label>If hired, when are you available to start?</label>
        <input
          required
          type='date'
          name='startAvailability'
          placeholder='Start Date?'
          value={formData.startAvailability}
          onChange={handleFormChange}
        />
        <label>Notes</label>
        <input
          type='text'
          name='notes'
          placeholder='Notes'
          value={formData.notes}
          onChange={handleFormChange}
        />
        <button onClick={handleSubmit}>Submit Application</button>
      </form>
      <MessageModal {...modal} />
      <Loading isOpen={isLoading} />
    </div>
  )
}

export default JobApplication