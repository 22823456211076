import React, { useEffect, useContext } from 'react'

import { checkUserAuth } from '../../../store/actions/userActions'
import { UserContext } from '../../../store/contexts/UserContext'

import ViewListings from '../listings/ViewListings'

const UserChecking = props => {
  
  const { user } = useContext(UserContext)

  useEffect(() => {
    checkUserAuth(props.history)
  })

  useEffect(() => {
    if (user.permissions && !user.permissions.includes('check') && !user.permissions.includes('admin')) {
      props.history.replace('/')
    }
  })

  return (
    <div className='UserChecking'>
      <ViewListings userId={props.match.params.slug} checkable={true} />
    </div>
  )
}

export default UserChecking