import React from 'react'
import { Link } from 'react-router-dom'

const Oops = props => {
  if (props.location.pathname !== '/admin') {
    return (
      <div className='Oops'>
        <h1>Oops, we can't find that page...</h1>
        <p>Try going <Link to='/'>Home</Link></p>
      </div>
    )
  } else {
    return null
  }
}

export default Oops