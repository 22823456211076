import * as firebase from 'firebase/app'

var firebaseConfig = {
  apiKey: "AIzaSyDiIp600NRxZufrrbaHO4Vh9ZuYTyfull0",
  authDomain: "prinicity-arbitrage.firebaseapp.com",
  databaseURL: "https://prinicity-arbitrage.firebaseio.com",
  projectId: "prinicity-arbitrage",
  storageBucket: "prinicity-arbitrage.appspot.com",
  messagingSenderId: "470289051613",
  appId: "1:470289051613:web:8d9da901e818d1f8275034",
  measurementId: "G-F8MPFL0P7H"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
// firebase.analytics();


export default firebase