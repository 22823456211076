import React from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

const MessageModal = props => {

  const customStyles = {
    content: {
      width: "50vw",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  }

  if (props.timeout) {
    setTimeout(() => {
      props.onModalClose()
    }, props.timeout)
  }

  return (
    <ReactModal
      style={customStyles}
      isOpen={props.isOpen}
      onRequestClose={props.onModalClose}
    >
      <div className='MessageModal'>
        <h1>{props.header}</h1>
        <p>{props.message}</p>
        <button onClick={props.onModalClose}>Close</button>
      </div>
    </ReactModal>
  )
}

export default MessageModal