import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER_EMAIL,
  UPDATE_USER_INFO
} from '../types'

export const userReducer = (state, action) => {
  switch (action.type) {

    case LOGIN_USER:
      const user = action.user
      return {
        ...state,
        ...user
      }

    case LOGOUT_USER:
      return {}

    case UPDATE_USER_EMAIL:
      const email = action.email
      return {
        ...state,
        email
      }

    case UPDATE_USER_INFO:
      const userInfo = action.userInfo
      return {
        ...state,
        ...userInfo
      }

    default:
      return state
  }
}