import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserContext } from '../../../store/contexts/UserContext'
import { loginUser } from '../../../store/actions/userActions'

import Loading from '../../helper/Loading'
import MessageModal from '../../helper/MessageModal'

const Login = props => {
  
  const { user, dispatch } = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: ''
  })
  
  useEffect(() => {
    if (user.uid) {
      props.history.replace('/')
    }
  })
  
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    loginUser(formData, dispatch)
      .then(res => {
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        setModal({
          isOpen: true,
          header: 'Unsuccessful Login Attempt',
          message: err,
        })
      })
  }

  return (
    <div className='Login'>
      <Loading isOpen={isLoading} />
      <MessageModal
        isOpen={modal.isOpen}
        header={modal.header}
        message={modal.message}
        onModalClose={() => setModal({...modal, isOpen: false})}
      />
      <h1>Login</h1>
      <form className="login-form" onSubmit={handleSubmit}>
        <label><FontAwesomeIcon icon='envelope' /></label>
        <input
          type='email'
          name='email'
          placeholder='Email'
          value={formData.email}
          onChange={handleChange}
        />
        <label><FontAwesomeIcon icon='lock' /></label>
        <input
          type='password'
          name='password'
          placeholder='Password'
          value={formData.password}
          onChange={handleChange}
        />
        <button>Login</button>
        <Link to='/forgot-password' className='forgot-link'>Forgot Password?</Link>
      </form>
    </div>
  )
}

export default Login