import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { checkUserAuth } from '../../../store/actions/userActions'
import { UserContext } from '../../../store/contexts/UserContext'

import NewListing from './NewListing'
import ViewListings from './ViewListings'

const List = props => {

  const { user } = useContext(UserContext)

  useEffect(() => {
    checkUserAuth(props.history)
  })

  useEffect(() => {
    if (user.permissions && !user.permissions.includes('list') && !user.permissions.includes('admin')) {
      props.history.replace('/')
    }
  })

  const [newListing, setNewListing] = useState(false)
  const [viewListings, setViewListings] = useState(false)

  return (
    <div className='List'>
      <h1>Listings</h1>
      <p
        className='clickable'
        onClick={() => setNewListing(true)}
      ><FontAwesomeIcon icon='plus-circle' /> Add New Listing
      </p>
      <p
        className='clickable'
        onClick={() => setViewListings(!viewListings)}
      ><FontAwesomeIcon icon='chart-bar' /> {viewListings ? 'Hide' : 'View'} My Listings
      </p>
      {newListing ?
        <NewListing
          isOpen={newListing}
          onModalClose={() => setNewListing(false)}
        />
      : null}
      {viewListings ?
        <ViewListings userId={user.uid} />
      : null}
    </div>
  )
}

export default List