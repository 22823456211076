import React, { useContext, useEffect, useState } from 'react'
import { startCase } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserContext } from '../../../store/contexts/UserContext'
import { checkUserAuth, changeUserEmail, changeUserInfo, changeUserPassword } from '../../../store/actions/userActions'

import Loading from '../../helper/Loading'
import MessageModal from '../../helper/MessageModal'

const Profile = props => {

  const { user, dispatch } = useContext(UserContext)

  useEffect(() => {
    checkUserAuth(props.history)
  })

  const [formData, setFormData] = useState({
    ...user,
    changePassword: '',
    confirmPassword: ''
  })
  const [editMode, setEditMode] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: '',
    timeout: null
  })

  const formFields = () => {
    const editableFields = ['email', 'firstName', 'lastName', 'payPal', 'skypeName', 'otherAbout']
    return Object.keys(user).map(key => {
      const title = startCase(key)
      if (editableFields.includes(key)) {
        return (
          <div className='profile-form-item' key={key}>
            <label>{title}:</label>
            <input
              type={key === 'email' ? 'email' : 'text'}
              name={key}
              placeholder={title}
              value={formData[key]}
              onChange={handleFormChange}
              disabled={!editMode.includes(key)}
            />
            {
              editMode.includes(key) ? (
                <>
                  <p
                    className='clickable'
                    onClick={() => {
                      setEditMode(editMode.filter(item => item !== key))
                      formData[key] = user[key]
                    }}
                  >Cancel</p>
                  <button onClick={() => handleFormSubmit(key)}>Save</button>
                </>
              ) :
                <FontAwesomeIcon
                  icon='edit'
                  className='clickable'
                  onClick={() => setEditMode([...editMode, key])}
                />
            }
          </div>
        )
      } else {
        return null
      }
    })
  }

  const openModal = (header, message='', timeout=null) => {
    setModal({
      isOpen: true,
      header,
      message,
      timeout
    })
  }

  const handleFormChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleFormSubmit = async key => {
    setEditMode(editMode.filter(item => item !== key))
    setIsLoading(true)
    if (key === 'email') {
      await changeUserEmail(formData.email, dispatch)
        .then(() => {
          setIsLoading(false)
          openModal('Email Changed Successfully', '', 3000)
        })
        .catch(err => {
          setIsLoading(false)
          console.log('error:', err)
          openModal('Unsuccessful Email Change', err)
        })
    } else if (key === 'password') {
      await changeUserPassword(formData.confirmPassword, dispatch)
        .then(() => {
          setIsLoading(false)
          openModal('Password Updated Successfully', '', 3000)
          setFormData({
            ...formData,
            changePassword: '',
            confirmPassword: ''
          })
        })
        .catch(err => {
          setIsLoading(false)
          console.log('error:', err)
          openModal('Unsuccessful Password Change', err)
        })
    } else {
      const title = startCase(key)
      await changeUserInfo(user.uid, {[key]: formData[key]}, dispatch)
        .then(res => {
          setIsLoading(false)
          openModal(`${title} Changed Successfully`, '', 3000)
        })
        .catch(err => {
          setIsLoading(false)
          console.log('error:', err)
          openModal(`Unsuccessful ${title} Change`, err)
        })
    }
  }

  const passwordMatch = () => {
    if (formData.changePassword && formData.confirmPassword) {
      if (formData.changePassword === formData.confirmPassword) {
        return (
          <div className="password-match green-text">Passwords match <FontAwesomeIcon icon='thumbs-up' /></div>
        )
      }
      else {
        return (
          <div className="password-match red-text">Passwords do not match <FontAwesomeIcon icon='thumbs-down' /></div>
        )
      }
    }
  }

  return (
    <div className='Profile'>
      <h1>Welcome, {user.firstName} {user.lastName}</h1>
      <form onSubmit={e => e.preventDefault()} className="profile-form">
        {formFields()}
        <div className='profile-form-item'>
          <label>Change Password:</label>
          <input
            type='password'
            name='changePassword'
            placeholder='New Password'
            value={formData['changePassword']}
            onChange={handleFormChange}
          />
        </div>
        {
          formData.changePassword ? (
            <>
              <div className='profile-form-item'>
                <label>Confirm Password:</label>
                <input
                  type='password'
                  name='confirmPassword'
                  placeholder='Same New Password'
                  value={formData['confirmPassword']}
                  onChange={handleFormChange}
                />
              </div>
              {passwordMatch()}
              <button
                className='password-button'
                onClick={() => handleFormSubmit('password')}
                disabled={formData.changePassword === formData.confirmPassword ? false : true}
              >Change Password</button>
            </>
          ) : null
        }
      </form>
      <Loading isOpen={isLoading} />
      <MessageModal
        isOpen={modal.isOpen}
        header={modal.header}
        message={modal.message}
        timeout={modal.timeout}
        onModalClose={() => setModal({
          ...modal,
          isOpen: false,
          timeout: null
        })}
      />
    </div>
  )
}

export default Profile