import {
  faSignOutAlt,
  faSignInAlt,
  faEdit,
  faSpinner,
  faLock,
  faEnvelope,
  faHome,
  faUser,
  faUserTie,
  faUserCog,
  faThumbsUp,
  faThumbsDown,
  faClipboardList,
  faClipboardCheck,
  faArrowCircleLeft,
  faShoppingBag,
  faChartBar,
  faPlusCircle,
  faBolt,
  faTruck,
  faStore,
  faTrash
} from "@fortawesome/free-solid-svg-icons"
import {library} from "@fortawesome/fontawesome-svg-core"

const Icons = () => {
  return library.add(
    faSignOutAlt,
    faSignInAlt,
    faEdit,
    faSpinner,
    faLock,
    faEnvelope,
    faHome,
    faUser,
    faUserTie,
    faUserCog,
    faThumbsUp,
    faThumbsDown,
    faClipboardList,
    faClipboardCheck,
    faArrowCircleLeft,
    faShoppingBag,
    faChartBar,
    faPlusCircle,
    faBolt,
    faTruck,
    faStore,
    faTrash
  )
}

export default Icons