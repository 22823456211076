import React, { useContext, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { isEmpty } from 'lodash'

import firebase from '../../../config/firebase'
import 'firebase/firestore'
import { UserContext } from '../../../store/contexts/UserContext'

import Loading from '../../helper/Loading'
import MessageModal from '../../helper/MessageModal'
import WalmartChecking from './WalmartChecking'

const FastChecking = props => {

  const { user } = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: '',
    onModalClose: () => setModal({...modal, isOpen: false})
  })
  const [listing, setListing] = useState({})
  const [lastTimestamp, setLastTimestamp] = useState('')
  const [trigger, setTrigger] = useState(false)

  const openModal = (header, message='') => {
    setModal(previousState => ({
      ...previousState,
      isOpen: true,
      header,
      message
    }))
  }

  const removeLock = () => {
    firebase.firestore().collection('listings').doc(listing.id).update({locked: false})
      .then(() => console.log('Listing Unlocked'))
      .catch(() => console.log('Listing Unlock Failed'))
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      if (props.open) {
        props.onClose()
        removeLock()
        return ''
      }
    }
  })

  useEffect(() => {
    if (props.open) {
      setIsLoading(true)
      const success = snapshot => {
        if (snapshot.empty) {
          setIsLoading(false)
          console.log('empty')
          openModal('There are no listings to check', 'If you believe this is an error, check your internet connection and refresh.')
        } else {
          snapshot.forEach(doc => {
            const docInfo = doc.data()
            if (docInfo.createdBy === user.uid) {
              setLastTimestamp(docInfo.createdAt)
            } else {
              docInfo.id = doc.id
              firebase.firestore().collection('listings').doc(doc.id).update({locked: true})
              .then(() => {
                setListing(docInfo)
                setIsLoading(false)
              })
              .catch(err => {
                openModal('There Was An Error Connecting to the DataBase', err.message + 'Please test your connection and try again.')
              })
            }
          })
        }
      }
      
      if (lastTimestamp) {
        firebase.firestore().collection('listings')
        .where('checked', '==', false)
        .where('locked', '==', false)
        .orderBy('createdAt')
        .startAfter(lastTimestamp)
        .limit(1)
        .get()
        .then(snapshot => success(snapshot))
        .catch(err => {
          setIsLoading(false)
          openModal('There Was An Error Getting Listings to Check', err.message)
        })
      } else {
        firebase.firestore().collection('listings')
        .where('checked', '==', false)
        .where('locked', '==', false)
        .orderBy('createdAt')
        .limit(1)
        .get()
        .then(snapshot => success(snapshot))
        .catch(err => {
          setIsLoading(false)
          openModal('There Was An Error Getting Listings to Check', err.message)
        })
      }
    }
  }, [props.open, user.uid, lastTimestamp, trigger])

  const handleSubmit = formData => {
    setIsLoading(true)
    const patchObj = {
      checked: true,
      locked: false,
      finalCheck: false,
      relistable: formData.relistable || false,
      check: {
        ...formData,
        checkedBy: user.uid,
      }
    }
    firebase.firestore().collection('listings').doc(listing.id).update(patchObj)
      .then(() => {
        setTrigger(!trigger)
      })
      .catch(err => {
        openModal('There Was An Error Connecting to the DataBase', err.message + 'Please test your connection and try again.')
      })
  }

  const currentListing = () => {
    if (!isEmpty(listing)) {
      if (listing.merchant === 'walmart') {
        return <WalmartChecking onSubmit={handleSubmit} {...listing} />
      } else if (listing.merchant === 'amazon') {
        return (
          <div className="fast-checking fast-checking-amazon">
            <h3>Amazon Listing</h3>
            <p>{listing.productName}</p>
          </div>
        )
      }
    } else {
      return null
    }
  }

  return (
    <ReactModal
      isOpen={props.open}
      onRequestClose={() => {
        props.onClose()
        removeLock()
      }}
    >
      <div className='FastChecking'>
        <h1>Fast Checking</h1>
        {currentListing()}
      </div>
      <Loading isOpen={isLoading} />
      <MessageModal {...modal} />
    </ReactModal>
  )
}

export default FastChecking