import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { camelCase } from 'lodash'

import firebase from '../../../config/firebase'
import 'firebase/firestore'

import Loading from '../../helper/Loading'
import MessageModal from '../../helper/MessageModal'

const AddSupplierMerchant = props => {

  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: '',
    timeout: null,
    onModalClose: () => setModal({...modal, isOpen: false})
  })

  const [formData, setFormData] = useState({
    title: '',
    productIdName: ''
  })

  const handleFormChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    const newObj = {...formData, camelName: camelCase(formData.title)}
    firebase.firestore().collection(`${props.path.name}s`).doc(newObj.camelName).set(newObj)
      .then(() => {
        setModal({
          ...modal,
          isOpen: true,
          header: `${props.path.title} Added Successfully`,
          timeout: 3000
        })
        setTimeout(() => {
          setIsLoading(false)
          props.onModalClose()
          setFormData({
            title: '',
            productIdName: ''
          })
        }, 3000)
      })
      .catch(err => {
        setIsLoading(false)
        setModal({
          ...modal,
          isOpen: true,
          header: `Failed to Add ${props.path.title}`,
          message: err.message
        })
      })
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onModalClose}
    >
      <Loading isOpen={isLoading} />
      <MessageModal {...modal} />
      <div className='AddSupplierMerchant'>
        <h1>Add {props.path.title}</h1>
        <form className='add-supplier-merchant-form'>
          <label>{props.path.title} Name</label>
          <input
            required
            type='text'
            name='title'
            placeholder={`${props.path.title} Name`}
            value={formData.title}
            onChange={handleFormChange}
          />
          <label>Product ID Name</label>
          <input
            required
            type='text'
            name='productIdName'
            placeholder='Product ID Name'
            value={formData.productIdName}
            onChange={handleFormChange}
          />
          <button onClick={handleSubmit}>Add {props.path.title}</button>
        </form>
      </div>
    </ReactModal>
  )
}

export default AddSupplierMerchant