import React from 'react'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

ReactModal.setAppElement('#root')

const Loading = props => {

  const customStyles = {
    content: {
      width: "50vw",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onModalClose}
      style={customStyles}
    >
      <div className='Loading'>
        <h1>Loading...</h1>
        <FontAwesomeIcon icon='spinner' spin={true} />
      </div>
    </ReactModal>
  )
}

export default Loading