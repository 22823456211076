import React, { useState } from 'react'
import moment from 'moment'
import { startCase } from 'lodash'

const WalmartChecking = props => {

  const [formData, setFormData] = useState({
    approved: false,
    restricted: true,
    brandOwner: true,
    newCondition: true,
    match: true,
    quantityIssues: true,
    prime: true,
    stockIssues: true
  })

  const handleFormChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === 'yes' ? true : false
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const newObj = {...formData}
    if (!formData.restricted && !formData.brandOwner && formData.newCondition && formData.match && !formData.quantityIssues && formData.prime && !formData.stockIssues) {
      newObj.approved = true
    }
    props.onSubmit(newObj)
  }

  return (
    <form className="fast-checking-form">
      <h3>Walmart Listing</h3>
      <div className="listing-details">
        <p>Listing Created: {moment.unix(props.createdAt).format('MMM Do YYYY')}</p>
        <p>Lister: {props.listerName}</p>
        <p>{props.productName}</p>
        <a href={props.supplierUrl} target='_blank' rel='noopener noreferrer'>Supplier Url</a>
        <p>{props.supplierProductId}</p>
        <a href={props.merchantUrl} target='_blank' rel='noopener noreferrer'>Walmart Url</a>
        <p>{props.merchantProductId}</p>
        <p>Supplier Price: ${props.supplierPrice.toFixed(2)}</p>
        <p>This is {props.multipack ? '' : 'not '}a multipack</p>
        <p>Quantity: {props.qty}</p>
        <p>Our Listed Walmart Price: ${props.ourPrice.toFixed(2)}</p>
        <p>Estimated Profit: ${props.estimatedProfit.toFixed(2)}, {(props.estimatedProfitPercent * 100).toFixed(2)}%</p>
        <p>Weight: {props.weight} lbs</p>
        <p>{props.notes ? `Notes: ${props.notes}` : null}</p>
      </div>
      <div className="check-item">
        <label>Is This Brand Restricted?</label>
        <label>
          <input
            type='radio'
            name='restricted'
            value='yes'
            checked={formData.restricted}
            onChange={handleFormChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='restricted'
            value='no'
            checked={!formData.restricted}
            onChange={handleFormChange}
          />
          <span>No</span>
        </label>
      </div>
      <div className="check-item">
        <label>Is This Product Listed By the Brand Owner?</label>
        <label>
          <input
            type='radio'
            name='brandOwner'
            value='yes'
            checked={formData.brandOwner}
            onChange={handleFormChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='brandOwner'
            value='no'
            checked={!formData.brandOwner}
            onChange={handleFormChange}
          />
          <span>No</span>
        </label>
      </div>
      <div className="check-item">
        <label>This Product is in New Condition</label>
        <label>
          <input
            type='radio'
            name='newCondition'
            value='yes'
            checked={formData.newCondition}
            onChange={handleFormChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='newCondition'
            value='no'
            checked={!formData.newCondition}
            onChange={handleFormChange}
          />
          <span>No</span>
        </label>
      </div>
      <div className="check-item">
        <label>Is The Product a Match?</label>
        <label>
          <input
            type='radio'
            name='match'
            value='yes'
            checked={formData.match}
            onChange={handleFormChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='match'
            value='no'
            checked={!formData.match}
            onChange={handleFormChange}
          />
          <span>No</span>
        </label>
      </div>
      <div className="check-item">
        <label>Are There any Quantity Issues (OOS or Low)?</label>
        <label>Qty: {props.qty} (Make sure Qty matches both {startCase(props.supplier)} and Walmart)</label>
        <label>
          <input
            type='radio'
            name='quantityIssues'
            value='yes'
            checked={formData.quantityIssues}
            onChange={handleFormChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='quantityIssues'
            value='no'
            checked={!formData.quantityIssues}
            onChange={handleFormChange}
          />
          <span>No</span>
        </label>
      </div>
      <div className="check-item">
        <label>Is This Prime?</label>
        <label>
          <input
            type='radio'
            name='prime'
            value='yes'
            checked={formData.prime}
            onChange={handleFormChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='prime'
            value='no'
            checked={!formData.prime}
            onChange={handleFormChange}
          />
          <span>No</span>
        </label>
      </div>
      <div className="check-item">
        <label>Are There Any Stock Issues?</label>
        <label>
          <input
            type='radio'
            name='stockIssues'
            value='yes'
            checked={formData.stockIssues}
            onChange={handleFormChange}
          />
          <span>Yes</span>
        </label>
        <label>
          <input
            type='radio'
            name='stockIssues'
            value='no'
            checked={!formData.stockIssues}
            onChange={handleFormChange}
          />
          <span>No</span>
        </label>
      </div>
      <button onClick={handleSubmit}>Submit</button>
    </form>
  )
}

export default WalmartChecking