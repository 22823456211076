import React, { useEffect, useCallback, useState } from 'react'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'
import moment from 'moment'
import { startCase } from 'lodash'

import firebase from '../../../config/firebase'
import 'firebase/firestore'
import MessageModal from '../../helper/MessageModal'

const ViewListings = ({ userId, checkable }) => {

  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: '',
    onModalClose: () => setModal({...modal, isOpen: false}),
    timeout: null
  })

  const [listings, setListings] = useState([])
  const [allListings, setAllListings] = useState(false)

  const thenFunc = useCallback(snapshot => {
    if (snapshot.size < 2) {
      setAllListings(true)
    }
    if (snapshot.empty) {
      setAllListings(true)
    } else {
      const listingsArr = []
      snapshot.forEach(doc => {
        const listingData = doc.data()
        listingData.id = doc.id
        listingsArr.push(listingData)
      })
      setListings(l => [...l, ...listingsArr])
    }
  }, [])
  const catchFunc = useCallback(err => {
    setModal(m => ({
      ...m,
      isOpen: true,
      header: 'Something Went Wrong Connecting to the DataBase',
      message: err.message
    }))
    console.log(err.message)
  }, [])

  useEffect(() => {
    const getListings = firebase.firestore().collection('listings').orderBy('createdAt', 'desc').limit(2)
    if (userId) {
      getListings.where('createdBy', '==', userId).get()
        .then(snapshot => thenFunc(snapshot))
        .catch(err => catchFunc(err))
    } else {
      getListings.get()
        .then(snapshot => thenFunc(snapshot))
        .catch(err => catchFunc(err))
    }
  }, [userId, catchFunc, thenFunc])

  const getMoreListings = () => {
    const getListings = firebase.firestore()
      .collection('listings')
      .orderBy('createdAt', 'desc')
      .startAfter(listings[listings.length - 1].createdAt)
      .limit(2)
    if (userId) {
      getListings.where('createdBy', '==', userId).get()
        .then(snapshot => thenFunc(snapshot))
        .catch(err => catchFunc(err))
    } else {
      getListings.get()
        .then(snapshot => thenFunc(snapshot))
        .catch(err => catchFunc(err))
    }
  }

  const [headerRow] = useState([{readOnly: true, value: ''}, {readOnly: true, value: 'Listing ID'}, {readOnly: true, value: 'Listed At'}, {readOnly: true, value: 'Lister Name'}, {readOnly: true, value: 'Merchant'}, {readOnly: true, value: 'Supplier'}, {readOnly: true, value: 'Product Name'}, {readOnly: true, value: 'Merchant URL'}, {readOnly: true, value: 'Merchant #'}, {readOnly: true, value: 'Supplier URL'}, {readOnly: true, value: 'Supplier #'}, {readOnly: true, value: 'Supplier Price'}, {readOnly: true, value: 'Qty'}, {readOnly: true, value: 'Merchant (our price)'}, {readOnly: true, value: '$Listed Profit'}, {readOnly: true, value: '%Listed Profit'}, {readOnly: true, value: 'Profitable?'}, {readOnly: true, value: 'Shipping Weight'}, {readOnly: true, value: 'Buy Box'}, {readOnly: true, value: 'Merchant Position'}, {readOnly: true, value: 'Listed BSR#'}, {readOnly: true, value: 'Listing Notes'}, {readOnly: true, value: 'Checking Timestamp'}, {readOnly: true, value: 'Checker Name'}, {readOnly: true, value: 'Prime'}, {readOnly: true, value: 'Match'}, {readOnly: true, value: 'Not Restricted'}, {readOnly: true, value: 'No Quantity Issues'}, {readOnly: true, value: 'Listing Status'}, {readOnly: true, value: 'Merchant Position'}, {readOnly: true, value: 'Checked BSR#'}, {readOnly: true, value: 'Listing Status'}, {readOnly: true, value: 'Reasons for B Listing'}, {readOnly: true, value: 'B Listing Notes'}, {readOnly: true, value: 'Reasons for Rejection'}, {readOnly: true, value: 'Rejection Notes'}, {readOnly: true, value: 'Checker Notes'}])
  const [grid, setGrid] = useState([])

  useEffect(() => {
    const newGrid = []
    let number = 1
    listings.forEach(listing => {
      const tempArr = []
      tempArr.push(
        {value: number},
        {value: checkable ? <span className='clickable'>{listing.id}</span> : listing.id},
        {value: moment.unix(listing.createdAt).format('lll')},
        {value: listing.listerName},
        {value: startCase(listing.merchant)},
        {value: startCase(listing.supplier)},
        {value: listing.productName},
        {value: listing.merchantUrl},
        {value: listing.merchantProductId},
        {value: listing.supplierUrl},
        {value: listing.supplierProductId},
        {value: '$' + listing.supplierPrice.toFixed(2)},
        {value: listing.qty},
        {value: '$' + listing.ourPrice.toFixed(2)},
        {value: '$' + listing.estimatedProfit.toFixed(2)},
        {value: (listing.estimatedProfitPercent * 100).toFixed(2) + '%'},
        {value: listing.profitable ? 'yes' : 'no'},
        {value: listing.weight},
        {value: listing.buyBox ? 'yes' : 'no'},
        {value: listing.position},
        {value: listing.bsr}
      )
      if (listing.hasOwnProperty('check')) {
        tempArr.push(
          {value: listing.listerNotes},
          {value: listing.check.checkedAt},
          {value: listing.check.checkerName},
          {value: listing.check.prime ? 'yes' : 'no'},
          {value: listing.check.match ? 'yes' : 'no'},
          {value: listing.check.restricted ? 'yes' : 'no'},
          {value: listing.check.quantityIssues ? 'yes' : 'no'},
          {value: listing.check.status},
          {value: listing.check.merchantPosition},
          {value: listing.check.bsr},
          {value: listing.check.status},
          {value: listing.check.reasonForB},
          {value: listing.check.bNotes},
          {value: listing.check.reasonsForRejection},
          {value: listing.check.rejectionNotes},
          {value: listing.check.checkerNotes}
        )
      } else {
        tempArr.push(
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''}
        )
      }
      newGrid.push(tempArr)
      number++
    })
    setGrid([
      headerRow,
      ...newGrid
    ])
  }, [listings, headerRow, checkable])

  return (
    <div className='ViewListings'>
      <ReactDataSheet
        data={grid}
        overflow='clip'
        valueRenderer={cell => cell.value}
      />
      <button onClick={getMoreListings} disabled={allListings}>Get More</button>
      <MessageModal {...modal} />
    </div>
  )
}

export default ViewListings