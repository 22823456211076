import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserContext } from '../../../store/contexts/UserContext'
import { checkUserAuth } from '../../../store/actions/userActions'

const Admin = props => {

  const { user } = useContext(UserContext)

  useEffect(() => {
    checkUserAuth(props.history)
  })

  useEffect(() => {
    if (user.permissions && !user.permissions.includes('admin')) {
      props.history.replace('/')
    }
  })

  if (props.history.location.pathname === "/admin") {
    return (
      <div className="Admin">
        <h1>Admin Home</h1>
        <p><Link to='/admin/users'><FontAwesomeIcon icon='user-cog' /> Users</Link></p>
        <p><Link to='/admin/merchants'><FontAwesomeIcon icon='store' /> Merchants</Link></p>
        <p><Link to='/admin/suppliers'><FontAwesomeIcon icon='truck' /> Suppliers</Link></p>
      </div>
    )
  } else {
    return null
  }
}

export default Admin