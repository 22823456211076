import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { isEmpty } from 'lodash'

import firebase from '../../../config/firebase'
import 'firebase/firestore'

import Loading from '../../helper/Loading'
import MessageModal from '../../helper/MessageModal'
import ListingForm from './ListingForm'

ReactModal.setAppElement('#root')

const NewListing = props => {

  const [isLoading, setIsLoading] = useState(false)

  const [modal, setModal] = useState({
    isOpen: false,
    header: '',
    message: '',
    onModalClose: () => setModal({...modal, isOpen: false})
  })

  const [merchants, setMerchants] = useState([])

  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    const newError = err => {
      setModal(currentModal => ({
        ...currentModal,
        isOpen: true,
        header: 'There was an error',
        message: 'Please check your internet connection and try again'
      }))
    }
    firebase.firestore().collection('suppliers').get()
      .then(snapshot => {
        const supplierList = []
        snapshot.forEach(doc => supplierList.push(doc.data()))
        setSuppliers(supplierList)
      })
      .catch(err => newError(err))

      firebase.firestore().collection('merchants').get()
      .then(snapshot => {
        const merchantList = []
        snapshot.forEach(doc => merchantList.push(doc.data()))
        setMerchants(merchantList)
      })
      .catch(err => newError(err))
  }, [])

  const [merchantOptions, setMerchantOptions] = useState([])
  const [currentMerchant, setCurrentMerchant] = useState({})
  useEffect(() => {
    setMerchantOptions(merchants.map(merchant => {
      return <option key={merchant.camelName} value={merchant.camelName}>{merchant.title}</option>
    }))
  }, [merchants])

  const [supplierOptions, setSupplierOptions] = useState([])
  const [currentSupplier, setCurrentSupplier] = useState({})
  useEffect(() => {
    const filteredSuppliers = suppliers.filter(supplier => supplier.camelName !== currentMerchant.camelName)
    setSupplierOptions(filteredSuppliers.map(supplier => {
      return <option key={supplier.camelName} value={supplier.camelName}>{supplier.title}</option>
    }))
  }, [suppliers, currentMerchant.camelName])

  const changeCurrentMerchant = value => {
    merchants.forEach(merchant => {
      if (merchant.camelName === value) {
        setCurrentMerchant(merchant)
        return
      }
    })
  }

  const changeCurrentSupplier = value => {
    suppliers.forEach(supplier => {
      if (supplier.camelName === value) {
        setCurrentSupplier(supplier)
        return
      }
    })
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onModalClose}
      portalClassName='new-listing-modal'
    >
      <div className='NewListing'>
        <Loading isOpen={isLoading} onModalClose={() => setIsLoading(false)} />
        <label className='header-label'>Merchant: </label>
        <label>(Where we will sell the product)</label>
        <select
          required
          value={currentMerchant.camelName}
          onChange={e => {
            changeCurrentMerchant(e.target.value)
            setCurrentSupplier({})
          }}
        >
          <option value=''></option>
          {merchantOptions}
        </select>
        {!isEmpty(currentMerchant) ? 
          <>
            <label className='header-label'>Supplier:</label>
            <label>(Where we will buy the product)</label>
            <select
              required
              value={currentSupplier.camelName}
              onChange={e => changeCurrentSupplier(e.target.value)}
            >
              <option value=''></option>
              {supplierOptions}
            </select>
          </>
        : null}
        {!isEmpty(currentSupplier) && !isEmpty(currentMerchant) ? <ListingForm merchant={currentMerchant} supplier={currentSupplier} /> : null}
      </div>
      <MessageModal {...modal} />
    </ReactModal>
  )
}

export default NewListing