import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserContext } from '../../store/contexts/UserContext'
import { checkUserAuth, logoutUser } from '../../store/actions/userActions'

const Home = props => {

  const { user, dispatch } = useContext(UserContext)
  
  useEffect(() => {
    checkUserAuth(props.history)
  })

  const handleLogout = () => {
    logoutUser(dispatch)
  }

  return (
    <div className='Home'>
      <h1>Hello there, <Link to='profile'>{user.firstName} {user.lastName}!</Link></h1>
      <p><Link to='list'><FontAwesomeIcon icon='clipboard-list' /></Link> List products or view listings</p>
      <p><Link to='check'><FontAwesomeIcon icon='clipboard-check' /></Link> Check listings</p>
      <p><Link to='order'><FontAwesomeIcon icon='shopping-bag' /></Link> Enter Orders</p>
      <br/>
      <button onClick={handleLogout}>Logout</button>
    </div>
  )
}

export default Home